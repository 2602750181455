import type { AdminVirtualMachine } from "@warrenio/api-spec/spec.oats.gen";
import { WVnc } from "../../components/Vnc.tsx";
import { useSuspenseQueryAtom } from "../../utils/query/useSuspenseQueryAtom.ts";
import { vmQueryAtom } from "./vmQuery.ts";

export function Vnc({ vmId, location }: { vmId: string; location: string }) {
    const vm: AdminVirtualMachine = useSuspenseQueryAtom(vmQueryAtom(location, vmId));

    return <WVnc vm={vm} location={location} isAdmin />;
}

export default Vnc;
